<template>
  <v-row class="pa-4" align="center" justify="center">
    <!-- <div class="text-right">
      <v-badge :content="messages" :value="messages" color="green" overlap>
        <v-icon medium> mdi-bell </v-icon>
      </v-badge>
    </div> -->
    <!-- <v-col cols="12" class="text-center"> -->
    <span class="text-center fn24-weight7 pb-4" style="color: #1a347f">
      Rewards
    </span>
    <!-- </v-col> -->
    <v-col cols="12">
      <v-autocomplete
        v-model="rewardSelected"
        :items="categoryRewards"
        style="color: #07204b; border: 1px solid #07204b"
        class="pa-2"
        item-text="category_name"
        item-value="id"
        rounded
        hide-details
        dense
      />
    </v-col>
    <v-divider class="my-2"></v-divider>
    <!-- <v-row class="mt-2" justify="center" align="center"> -->
    <!-- cols="12" md="4" -->
    <v-col
      cols="12"
      v-for="(item, index) in items"
      :key="index"
      style="text-align: -webkit-center"
    >
      <v-card
        class="mb-4 mr-4 rounded-xl"
        max-width="250"
        min-width="250"
        min-height="350"
        max-height="350"
        @click="goToRewards(item)"
        v-if="item.status == 1"
      >
        <!-- <div
            class="mx-auto"
            align="center"
            justify="center"
            style="background: #1a347f"
          >
            <v-img class="object-cover my-4" :aspect-ratio="4/3"  :src="item.rewardImg" />
            <div
              class="font-weight-bold white--text pa-0 mb-0"
              style="font-weight: 700; font-size: 40px"
            >
              10%
            </div>
            <div class="white--text fn14-weight7 text-uppercase ma-0 py-4">
              Discount Coupon
            </div>
          </div> -->

        <v-img class="object-cover my-4" :src="item.rewardImg" />

        <v-card-subtitle
          class="pa-0 mx-2 my-2 text-left fn12-weight7"
          style="color: #06204b"
        >
          {{ item.reward_name }}
        </v-card-subtitle>

        <v-card-actions class="pa-0 my-2">
          <img
            src="@/assets/BI.png"
            height="15px"
            width="15px"
            align="left"
            justify="left"
            class="ml-2"
          />

          <div class="ml-1 fn10" style="color: #1a347f; font-weight: 600">
            <!-- {{ item.point }} -->
            Use {{ item.point }} Points to redeem
          </div>
        </v-card-actions>
      </v-card>
    </v-col>
    <!-- </v-row> -->
  </v-row>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      rewardSelected: 0,
      // messages: 1,
      // create_by: null
      // createdAt: "2022-02-21T11:05:11.349Z"
      // date: "2022-02-21T00:00:00.000Z"
      // description: "com.com.com.com"
      // expired_date: null
      // id: 34
      // point: 100000000
      // redeem: null
      // rewardCategoryId: 9
      // rewardImg: ""
      // reward_category: {category_name: 'test add category'}
      // reward_name: "test edit last"
      // status: "1"
      // update_by: null
      // updatedAt: "2022-02-21T11:0
      items: [
        // {
        //   imageLogo: require("@/assets/logo_reward.png"),
        //   discounts: "10%",
        //   typeCoupon: "Discount Coupon",
        //   reward_name: "",
        //   point: "",
        // },
        // {
        //   imageLogo: require("@/assets/logo_reward.png"),
        //   discounts: "10%",
        //   typeCoupon: "Discount Coupon",
        //   reward_name: "",
        //   point: "",
        // },
        // {
        //   imageLogo: require("@/assets/logo_reward.png"),
        //   discounts: "10%",
        //   typeCoupon: "Discount Coupon",
        //   reward_name: "",
        //   point: "",
        // },
        // {
        //   imageLogo: require("@/assets/logo_reward.png"),
        //   discounts: "10%",
        //   typeCoupon: "Discount Coupon",
        //   reward_name: "",
        //   point: "",
        // },
        // {
        //   imageLogo: require("@/assets/logo_reward.png"),
        //   discounts: "10%",
        //   typeCoupon: "Discount Coupon",
        //   reward_name: "",
        //   point: "",
        // },
        // {
        //   imageLogo: require("@/assets/logo_reward.png"),
        //   discounts: "10%",
        //   typeCoupon: "Discount Coupon",
        //   reward_name: "",
        //   point: "",
        // },
      ],
      categoryRewards: [
        // { id: 1, category_name: "Discount Coupon" }
      ],
      userData: [],
    };
  },
  watch: {
    rewardSelected(val) {
      if (val) {
        console.log("val", val);
        this.getAllRewards(val);
      }
    },
  },
  async created() {
    // await this.getUser();
    await this.getProfile();
    // this.getAllRewards();
    // await this.getAllRewardCategory();
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
       const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
      localStorage.setItem("userBiData", Encode.encode(this.userData));
      this.getAllRewardCategory();
    },
    async getAllRewardCategory() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reward_category?usertype=${this.userData.species_type}
          `
      );
      this.categoryRewards = response.data.data;
      this.categoryRewards.push({
        id: 999999999,
        category_name: "ทั้งหมด",
      });
      this.rewardSelected = 999999999;
      console.log("categoryRewards", this.categoryRewards);
    },
    goToRewards(item) {
      // this.$router.push("RewardsPage", item.id);
      this.$router.push({
        path: "RewardsPage",
        query: {
          reward: item.id,
        },
      });
    },
    async getAllRewards(val) {
      if (this.rewardSelected == 999999999) {
        val = "";
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/reward?usertype=${this.userData.species_type}`
        );
        console.log("getAllRewardsBUTfilterByType", response.data);
        this.items = response.data.data;
      } else {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/reward?rewardCategoryId=${val}`
        );
        console.log("getAllRewards", response.data);
        this.items = response.data.data;
      }
    },
  },
};
</script>
